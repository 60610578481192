@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-item {
  @apply py-[20px] px-[50px] h-full cursor-pointer font-semibold transition-all mt-4 md:mt-0 lg:mt-0 hover:bg-white hover:text-themeColor hover:px-[60px] hover:font-extrabold focus:bg-white focus:text-themeColor focus:px-[60px] focus:font-extrabold
}

.nav-drop-down-item {
  @apply block p-4 hover:bg-gray-200 text-themeColor font-bold
}

.input-field {
  @apply flex flex-col text-[18px]
}

.themed-input {
  @apply px-4 py-2 border border-gray-300 rounded-lg shadow-inner bg-white appearance-none
}